<template>
  <div>
    <r-button
      width="content"
      type="primary"
      color="rocky"
      title="Получить отчет"
      @click="getReport"
    />
  </div>
</template>

<script>
import PlaceApi from '../api/PlaceApi';

export default {
  name: 'DownloadReportModal',
  methods: {
    async getReport() {
      await new PlaceApi().binsTruckLog().then(res => {
      });
    }
  }
};
</script>

<style scoped>

</style>
