<template>
  <r-popover
    content-class="rir-select__content rir-popover__radius"
    :disabled="disabled"
    v-model="showSelect"
  >
    <template #activator="{ on }">
      <r-input
        readonly
        :value="
          (!readonly ? ((
            (mondays ? ', Пн' : '') +
            (tuesday ? ', Вт' : '') +
            (wednesday ? ', Ср' : '') +
            (thursday ? ', Чт' : '') +
            (friday ? ', Пт' : '') +
            (saturday ? ', Сб' : '') +
            (sunday ? ', Вс' : '') +
            (paper ? ', Бумага' : '') +
            (glass ? ', Стекло' : '') +
            (metal ? ', Металл' : '') +
            (plastic ? ', PET пластик' : '') +
            (other ? ', Другое' : '')).substr(2)) : '')
        "
        :placeholder="placeholder"
        @click.native="on.click"
        class="rir-select"
        :label="label"
        :class="{
          disabled: readonly
        }"
      >
        <a
          v-if="rubbish && !readonly"
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            :size="20"
            :class="{
              active: showSelect
            }"
            icon="arrow-down"
          />
        </a>
        <a
          v-else
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            :size="20"
            icon="arrow-down"
          />
        </a>
      </r-input>
    </template>
    <r-list
      overflow
      v-if="rubbish && !readonly"
    >
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="paper = !paper"
            :value="paper ? '' : []"
            label="Бумага"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="glass = !glass"
            :value="glass ? '' : []"
            label="Стекло"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="metal = !metal"
            :value="metal ? '' : []"
            label="Металл"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="plastic = !plastic"
            :value="plastic ? '' : []"
            label="PET пластик"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="other = !other"
            :value="other ? '' : []"
            label="Другое"
          />
        </template>
        <slot name="item" />
      </r-list-item>
    </r-list>

    <r-list
      overflow
      v-if="days"
    >
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="mondays = !mondays"
            :value="mondays ? '' : []"
            label="Понедельник"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="tuesday = !tuesday"
            :value="tuesday ? '' : []"
            label="Вторник"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="wednesday = !wednesday"
            :value="wednesday ? '' : []"
            label="Среда"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="thursday = !thursday"
            :value="thursday ? '' : []"
            label="Четрверг"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="friday = !friday"
            :value="friday ? '' : []"
            label="Пятница"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="saturday = !saturday"
            :value="saturday ? '' : []"
            label="Суббора"
          />
        </template>
        <slot name="item" />
      </r-list-item>
      <r-list-item>
        <template>
          <r-checkbox
            class="mb-1 opacity"
            @input="sunday = !sunday"
            :value="sunday ? '' : []"
            label="Воскресенье"
          />
        </template>
        <slot name="item" />
      </r-list-item>
    </r-list>
  </r-popover>
</template>

<script>

export default {
  name: 'Select',
  components: {},
  props: {
    value: {
      validator: prop => ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    textValue: {
      type: String,
      default: 'value'
    },
    idValue: {
      type: String,
      default: 'id'
    },
    noDataItems: {
      type: String,
      default: 'Список пустой'
    },
    rubbish: {
      type: Boolean,
      default: false
    },
    days: {
      type: Boolean,
      default: false
    },
    garbage: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    paper: false,
    glass: false,
    metal: false,
    plastic: false,
    other: false,
    daily: false,
    mondays: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  }),
  computed: {
    itemsList() {
      const items = this.items.map(el => ({
        ...el
      }));
      if (this.selectAll) {
        items.unshift({
          [this.idValue]: null,
          [this.textValue]: 'Все'
        });
      }
      return items;
    }
  },
  watch: {},
  mounted() {
  },
  created() {
    this.other = false;
    if (this.rubbish) {
      if (this.garbage && !this.readonly) {
        var garbageItem = this.garbage.split(',');
        garbageItem.forEach(element => {
          // console.log(element);
          if (element === 'Бумага') {
            this.paper = true;
          }
          if (element === 'Стекло') {
            this.glass = true;
          }
          if (element === 'Металл') {
            this.metal = true;
          }
          if (element === 'PET пластик') {
            this.plastic = true;
          }
          if (element === 'Другое') {
            this.other = true;
          }
        });
      }
    } else if (this.garbage) {
      var garbageItem = this.garbage.split(',');
      garbageItem.forEach(element => {
        // console.log(element);
        if (element === 'Пн') {
          this.mondays = true;
        }
        if (element === 'Вт') {
          this.tuesday = true;
        }
        if (element === 'Ср') {
          this.wednesday = true;
        }
        if (element === 'Чт') {
          this.thursday = true;
        }
        if (element === 'Пт') {
          this.friday = true;
        }
        if (element === 'Сб') {
          this.saturday = true;
        }
        if (element === 'Вс') {
          this.sunday = true;
        }
      });
    }
  },
  methods: {
    emitMusor() {
      const value = (!this.readonly
        ? (((this.mondays ? ',Пн' : '')
        + (this.tuesday ? ',Вт' : '')
        + (this.wednesday ? ',Ср' : '')
        + (this.thursday ? ',Чт' : '')
        + (this.friday ? ',Пт' : '')
        + (this.saturday ? ',Сб' : '')
        + (this.sunday ? ',Вс' : '')
        + (this.paper ? ',Бумага' : '')
        + (this.glass ? ',Стекло' : '')
        + (this.metal ? ',Металл' : '')
        + (this.plastic ? ',PET пластик' : '')
        + (this.other ? ',Другое' : '')).substr(1)) : '');

      return value;
    }
  }
};
</script>
<style lang="scss">
.opacity{
  opacity: 1;
}
</style>
