<template>
  <div id="app">
    <router-view />
    <r-notification />
    <r-modal />
  </div>
</template>
<script>
import Vue from 'vue';
import PlaceApi from '@/api/PlaceApi';

export default {
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
    && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window?.ymaps && delete window.ymaps;
  },
  async created() {
    // Vue.prototype.$modal = this.$rir.modal.open;
    await new PlaceApi().getGarbageTypes().then(res => {
      this.$store.commit('setGarbageTypes', res.ntechlabConfig.tkoTypes);
    });
  }
};
</script>
<style lang="scss">
.modal-open {
  overflow: hidden;
}
</style>
<style>
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}

.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}

.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
</style>
