<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          @click="close"
          class="ml-auto mr-2 mt-2 pointer"
          style="width: 32px;"
        >
          <r-icon
            class="mr-2 icon__close"
            icon="close"
            fill="rocky"
            size="20"
          />
        </div>
        <div class="parent align-center">
          <div
            class="container-upload"
          >
            <h1 class="ricotta mb-6">
              Редактор конфигурации
            </h1>
            <r-textarea
              class="mt-6 mb-6"
              :value="settings"
              label="Стандартный редактор"
              v-model="settings"
              :rows="25"
              style="display: table;"
            />
          </div>
          <r-button
            class="flex-1 mt-6 container-upload_zona mb-4"
            @click="save(settings)"
            width="wide"
            title="Сохранить"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/PlaceApi';
import rir from "@/plugins/RirLib";

const api = new Api();
export default {
  name: 'SelectionSettings',
  components: {
  },
  props: {
  },
  data() {
    return {
      upload: false,
      settings: '',
      item: ''
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    close() {
      this.upload = false;
    },
    async edit() {

      let items = [];
      let json = {
        fields: [
            "ntechlabConfig"
        ]
      }
      items = await api.getConfig(json);
      this.settings = JSON.stringify(items.ntechlabConfig, null, 2);
      this.upload = true;
    },
    save(settings) {

      try {
        let json = {
          ntechlabConfig: ''
        };
        json.ntechlabConfig = JSON.parse(
          settings
            .replace(/\n/g,'')
            .replace(/\t/g,'')
        );

        api.setConfig({json}).then(function (el) {
          if(el.ok === 'ok')
          this.$rir.notification.send({
            title: 'Данные конфигурации сохранены',
            seconds: 5,
            hiddenClose: true,
            iconOptions: {
              icon: 'warning',
              fill: 'fargo'
            }
          });
        });
        this.upload = false;
      } catch (err) {
        this.$rir.notification.send({
          title: 'Не валидный JSON формат',
          seconds: 0,
          hiddenClose: true,
          iconOptions: {
            icon: 'warning',
            fill: 'fargo'
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.align-center {
  align-items: center;
  margin-top: 5px;
}
.align-centerEnd {
  align-items: center;
  margin-top: 5px;

}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 60%;
  height: 400px;
  top: 30%;
  margin-left: 22%;
  margin-top: -150px;
  @media(max-width: 599px){
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding: 0 2.5vw;
  }
}

.container-upload {
  width: 100%;
  @media(max-width: 599px){
    width: 100%
  }
}
.container-upload_zona {
  @media(max-width: 599px){
    width: 100%
  }
}

.parentEnd {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 50%;
  margin-left:28%;
  margin-top: -150px;
}

.container-uploadEnd {
  width: 55%;
  height: 300px;
}

.margin-right {
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.culture {
  max-width: 100%;
  flex-direction: column-reverse;

  &__cards {
    width: 100%;
  }

  &__map-cont {
    position: relative;
    top: unset;
    background-color: #fff;
    height: unset;
    padding: 0;
    margin-left: unset;
    width: 100%;
  }

  &__map {
    margin-top: 40px;
    height: 400px;
    margin-bottom: 32px;
  }

  &__map--collapsed {
    height: 112px;
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: block;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dashed #214eb052;
    }
  }

}
::v-deep .vue-slider-process {
  background-color: #4480F3 !important;
}
::v-deep .vue-slider-rail {
 background-color: #BCD6FE !important;
}
::v-deep .vue-slider-dot-handle {
  background-color: #4480F3 !important;
  box-shadow: 0px 0px 0px 5px rgba(240, 245, 254, .2) !important;
}
.step {
  display: table;
  height: 100%;
  width: 120px;
}
.step_cont {
  width: calc(100% - 200px);
  height: 43px;
  margin-left: 5px;
}
.step_text {
  width: calc(100% - 130px);
}
.tooltip .tooltiptext {
  margin-top: -80px;
  margin-left: 5px;
  visibility: hidden;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltipicon {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #FFFFFF transparent  transparent transparent;
  top: 30px;
  left: 10px;
}
.objects {
  &__filter {
    display: flex;
  }
}
.icon__close {
  top: 12px;
  right: 2px;
  position: absolute;
}
</style>
<style>
.RSelect__content {
  z-index: 1000 !important;
}
</style>
