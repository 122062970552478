<template>
  <section
    class="flex"
    style="flex-direction: column;"
  >
    <div class="camembert mb-10">
      {{ getName }}
    </div>
    <div class="flex">
      <div
        class="flex mr-8"
        style="flex-direction: column;"
      >
        <div class="taleggio mb-6">
          Выберите фрагмент
        </div>
        <r-date-picker
          class="mb-6"
          label="Дата"
          v-model="date"
          click-close
          :is-input="false"
        />
        <r-time-picker
          v-model="time"
          :is-seconds="true"
          label="Время"
        />
      </div>
      <video
        ref="video"
        style="aspect-ratio: 16/10"
        width="482"
        controls
      />
    </div>
    <r-button
      @click="getVideoTime"
      class="mt-8"
      width="wide"
      title="Применить"
    />
  </section>
</template>

<script>
import Hls from 'hls.js';
import TimePicker from '@/components/TimePicker';

export default {
  name: 'ArchiveVideoModal',
  components: { TimePicker },
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    timeout: null,
    date: new Date(),
    time: null
  }),
  computed: {
    getName() {
      return this.item.screenshot.channel.name;
    }
  },
  created() {
    const date = new Date(this.item.timestamp * 1000);
    this.date = `${date.getUTCFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
    this.time = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
  },
  mounted() {
    this.getVideo();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    getVideoTime() {
      const timestamp = new Date(`${this.date} ${this.time}`).getTime() / 1000;
      // console.log('timestamp', timestamp)
      this.getVideo(timestamp);
    },
    async getVideo(timestamp) {
      // https://smartvolgodonsk.ru/trassir/rest/archive/stream/<channel id>/master.m3u8?start=<unix TS в секундах>
      const player = this.$refs.video;
      const channelId = this.item.screenshot.channel.id;
      const timestampStart = timestamp || this.item.timestamp;
      // console.log(el, channelId, timestamp)
      // const res = await api.getArchiveVideo(channelId, timestamp)
      // console.log(res)
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(`/trassir/rest/archive/stream/${channelId}/master.m3u8?startTime=${timestampStart}`);
        hls.attachMedia(player);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.timeout = setTimeout(() => {
            // console.log('initVideoTwo');
            player.play();
          }, 2000);
        });
      }
    }
  }
};
</script>
