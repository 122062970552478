<template>
  <div class="objects">
    <empty
      :load-data="loadData"
      :is-under-vision-handler="isUnderVisionHandler"
      v-show="this.$store.state.isEmpty"
    />
    <div v-show="!this.$store.state.isEmpty">
      <div class="mt-6 flex mb-6">
        <upload
          @fileLoaded="loadData"
          @isUnderVision="isUnderVisionHandler"
        />
        <div class="float-right" />
      </div>
      <div class="objects__filter">
        <r-select
          v-model="searchCollectionPoints"
          class="flex-1"
          :items="sortCollectionPoints"
          @input="isUnderVisionHandler"
          label="Точки сбора"
        />
        <r-select
          v-model="searchType"
          class="flex-1 ml-6"
          :items="$store.state.garbageTypes"
          @input="searchCard"
          label="Тип"
        />
        <r-input
          before-icon="search"
          class="flex-1 ml-6"
          label="Поиск"
          @keyup="searchCard"
          v-model="search"
        />
        <div
          v-show="activeTabId !== 'moderation'"
          class="flex ml-6"
        >
          <r-button-simple
            size="larishae"
            icon="icon-view"
            icon-size="20"
            @click="selectedView = 'table'"
            :type="selectedView === 'map' ? 'secondary' : 'primary'"
          />
          <r-button-simple
            v-show="activeTabId !== 'moderation'"
            size="larishae"
            class="ml-2"
            icon="geopoint"
            :type="selectedView === 'map' ? 'primary' : 'secondary'"
            icon-size="20"
            @click="onChangeMap"
          />
        </div>
      </div>
      <loader v-if="isLoading" />
      <template v-else>
        <div
          v-if="updatedCallback()"
        />
        <div class="flex align-center mt-6">
          <r-checkbox
            v-if="selectedView === 'map' && activeTabId !== 'moderation'"
            class="mr-6"
            title="Тепловая карта обращений"
            v-model="heatmap"
            @input="heatmapFunc"
          />
          <div
            class="flex align-center"
            v-if="selectedView === 'map' && activeTabId !== 'moderation'"
          >
            <r-checkbox
              class="mr-6"
              title="Обращения о мусоре"
              v-model="appealsGarbage"
              @input="getAppealsGarbage(true)"
            />
            <dropdown-datepicker
              @select="selectPeriod"
              :readonly="!appealsGarbage && !heatmap"
              :date-from="dateFrom"
              :date-to="dateTo"
            />
          </div>
          <div class="parmigiano opacity-48 ml-auto">
            {{ !!search ? 'Найдено' : 'Всего' }} {{ objectsCount }}
          </div>
        </div>
        <div v-show="activeTabId === 'moderation' || selectedView === 'table'">
          <loadCard
            :is-view="isView"
            :check="isCheck"
            :check-house="isCheckHouse"
            :is-search="isSearch"
            :is-type="isType"
            :is-under-vision="searchCollectionPoints"
            @count="val => objectsCount = val"
            v-show="objectsCount > 0"
          />
          <not-found v-show="objectsCount < 1" />
        </div>

        <div
          v-if="selectedView === 'map' && activeTabId !== 'moderation'"
          :key="count"
        >
          <map-object
            :is-view="isView"
            @count="val => objectsCount = val"
            :check="isCheck"
            :is-check-musor="isCheckMusor"
            :check-house="isCheckHouse"
            :is-search="isSearch"
            ref="map"
            :is-type="searchType"
            :is-under-vision="searchCollectionPoints"
            :heatmap="heatmap"
            :appeals-garbage="appealsGarbage"
            :appeals-garbage-data="appealsGarbageData"
            :heatmap-data="heatmapData"
            :date-from="dateFrom"
            :date-to="dateTo"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DropdownDatepicker from '@/components/DropdownDatepicker.vue';
import Loader from '@/components/Loader.vue';
import Upload from './Upload.vue';
import loadCard from './loadCard.vue';
import MapObject from './Map.vue';
import Empty from './Empty';
import notFound from './NotFound';
import PlaceApi from '../api/PlaceApi';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    Upload,
    loadCard,
    MapObject,
    DropdownDatepicker,
    Empty,
    notFound
  },
  props: {
    isView: {
      type: String
    }
  },
  data() {
    return {
      count: 0,
      file: String,
      activeTabId: 'published',
      selectedView: 'table',
      componentKey: 0,
      isCheck: false,
      isCheckMusor: false,
      isCheckHouse: null,
      isSearch: '',
      isType: '',
      search: null,
      searchType: 0,
      searchCollectionPoints: 0,
      map: true,
      sortCollectionPoints: [
        {
          title: 'Все',
          id: 0
        }
      ],
      sortTypes: [
        {
          title: 'Все',
          id: 0
        }
        // {
        //   title: 'Контейнер',
        //   id: 1
        // },
        // {
        //   title: 'Площадка ТКО',
        //   id: 2
        // },
        // {
        //   title: 'Бункер-накопитель',
        //   id: 3
        //   // 'icon': 3
        // },
        // {
        //   title: 'Мусорокамера',
        //   id: 4
        // },
        // {
        //   title: 'Раздельный сбор',
        //   id: 5
        // }
      ],
      objectsCount: null,
      heatmap: false,
      appealsGarbage: false,
      appealsGarbagePeriod: [],
      appealsGarbageData: [],
      heatmapData: [],
      dateFrom: null,
      dateTo: null,
      userRoot: false,
      mapDisabled: true
    };
  },

  computed: {
    countRow() {
      return this.$store.state.countObjects;
    },
    // пред загрузка
    isLoading() {
      if (this.isView == 'analytics' || this.isView == 'video') {
        this.sortCollectionPoints = [
          {
            title: 'Все',
            id: 0
          },
          {
            title: 'Без видеонаблюдения',
            id: 1
          },
          {
            title: 'Под видеонаблюдением',
            id: 2
          }];
      }

      return this.$store.state.isObjectLoading;
    }
  },
  created() {
    this.$store.dispatch('loadObjects');
    this.$store.dispatch('loadCoors');
    this.getAppealsGarbage(true);
  },
  methods: {
    selectPeriod(period) {
      this.dateFrom = period.dateFrom;
      this.dateTo = period.dateTo;
      this.getAppealsGarbage(true);
    },
    getAppealsGarbage(val) {
      if (val) {
        new PlaceApi().getGarbage({
          currentMainMenuNumber: 51,
          readOnly: false,
          type: -1,
          from: this.dateFrom,
          to: this.dateTo
        }).then(res => {
          this.appealsGarbageData = res.all;
        });
      } else {
        this.appealsGarbageData = [];
      }
      if (this.heatmap) {
        this.count++;
      }
    },
    heatmapFunc(val) {
      this.count++;
    },
    isUnderVisionHandler(state) {
      // if (this.selectedView === 'map') this.onChangeHide();
      this.count++;
    },
    loadData() {
      this.$store.dispatch('loadObjects');
    },
    async onChangeHide() {
      this.isCheck = !this.isCheck;

      this.componentKey += 1;
    },
    // Поск по району
    onChangeMap() {
      this.selectedView = 'map';
      this.componentKey += 1;
      this.count++;
    },
    searchCard() {
      this.componentKey += 1;
      this.isSearch = this.search !== null ? this.search.toLowerCase() : '';
      this.isType = String(this.searchType);
      this.count++;
    },
    updatedCallback() {
      if (this.mapDisabled) {
        if (this.$route?.query?.point) {
          this.searchCollectionPoints = Number(this.$route.query.point);
        }
        if (this.$route?.query?.type) {
          this.searchType = Number(this.$route.query.type);
          this.isType = String(this.$route.query.type);
        }
      }
      this.mapDisabled = false;

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.float-right {
  margin: 0 0 0 auto;
}

.color-card {
  color: #0e1420;
}

.not_found {
  margin: 100px 0;
  display: grid;
  justify-content: center;
  justify-items: center;
}
</style>
