<template>
  <div>
    <!--    <div-->
    <!--      class="flex sulguni mb-7 print"-->
    <!--      @click="back"-->
    <!--    >-->
    <!--      <r-icon-->
    <!--        class="mr-2"-->
    <!--        icon="arrow-left"-->
    <!--        style="margin-top: 2px;"-->
    <!--      />-->
    <!--      Назад-->
    <!--    </div>-->
    <template>
      <loader v-if="isLoading" />
      <div v-else>
        <div class="mt-8">
          <div>
            <div class="mt-6 flex mb-6">
              <r-date-picker
                class="flex"
                label="Дата"
                v-model="startDate"
                :is-clear-model="true"
                @change="inputDate"
                :start-date="getStartDateTime.date"
              />
              <r-time-picker
                class="ml-2 flex"
                label="Время"
                v-model="startTime"
                :is-clear-model="true"
                @input="inputTime"
                :start-time="getStartDateTime.time"
              />
            </div>
            <div v-if="showInfo">
              <div
                v-for="cam in cams"
              >
                <div>
                  <div
                    v-if="cam?.status"
                    class="sulguni mb-2"
                  >
                    Статус:
                    <span
                      :class="statusInfo(cam.status).color"
                    >
                      {{ statusInfo(cam.status).text }}
                    </span>
                  </div>
                  <div
                    v-if="cam?.createdSource"
                    class="sulguni mb-4 metropolis--text"
                  >
                    Дата обновления:
                    {{ dateFilterOutput(cam?.createdSource) }}
                  </div>
                  <div
                    v-if="shot_dt"
                    class="sulguni mb-4 metropolis--text"
                  >
                    Возможное появление мусоровоза:
                    {{ dateFilterOutput(shot_dt) }}
                  </div>
                  <div
                    v-if="camStatus !== 1 && substat"
                    class="sulguni mb-2"
                  >
                    Нарушения:
                  </div>
                  <div
                    v-for="(status, index) in cam?.substatuses"
                    v-if="camStatus !== 1 && status === 3 && statusAll(index)"
                    class="roquefort mb-1 ml-4"
                  >
                    <span
                      class="fargo--text"
                    >
                      {{ statusAll(index) }}
                    </span>
                  </div>

                  <div
                    class="expand-photo__wrapper pointer mt-6"
                  >
                    <img
                      v-if="photoError === false"
                      @click="onViewImage(cam?.photo)"
                      :src="cam?.photo"
                      class="expand-photo__main-photo"
                      @error="photoError = true"
                    >
                    <p
                      v-else
                      class="sulguni"
                    >
                      Нет изображения
                    </p>
                  </div>
                  <div
                    v-if="photoError === false"
                    class="d-flex align-items-center mt-4"
                    style="margin-bottom: 120px;"
                  >
                    <div class="d-flex align-items-center mr-4">
                      <r-icon
                        icon="geoposition"
                        size="16"
                        fill="matrix"
                        class="mr-2"
                      />
                      <p class="sulguni">
                        Контейнер
                      </p>
                    </div>
                    <div class="d-flex align-items-center mr-4">
                      <r-icon
                        icon="geoposition"
                        size="16"
                        fill="gentlemen"
                        class="mr-2"
                      />
                      <p class="sulguni">
                        Контейнерная площадка
                      </p>
                    </div>
                    <div class="d-flex align-items-center">
                      <r-icon
                        icon="geoposition"
                        size="16"
                        fill="fargo"
                        class="mr-2"
                      />
                      <p class="sulguni">
                        Мусор вне контейнера
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <not-found v-else />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import PlaceApi from '@/api/PlaceApi';
import ModelViewImage from '@/components/ModelViewImage.vue';
import notFound from '@/components/NotFound.vue';
import Loader from '@/components/Loader.vue';

const api = new PlaceApi();

export default {
  components: {
    Loader,
    notFound
  },
  props: {
    cameraGuid: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: true,
      startDate: null,
      startTime: null,
      cams: [],
      statusHistoryDatetime: null,
      shot_dt: null,
      substat: false,
      camStatus: 0,
      firstTimeLogged: null,
      photoError: false
    };
  },
  computed: {
    // пред загрузка
    // isLoading() {
    //   return this.$store.state.isObjectLoadingId;
    // }
    showInfo() {
      if (this.cams.length > 0) {
        if (this.cams[0]?.photo !== null) {
          return true;
        }
      }
      return false;
    },
    getStartDateTime() {
      if (this.firstTimeLogged !== null) {
        const date = moment(this.firstTimeLogged);
        const startDate = moment(this.startDate);
        return {
          date: date.format('YYYY-MM-DD'),
          time: date.diff(startDate) === 0 ? moment(this.firstTimeLogged).format('hh:mm:ss') : null
        };
      }
      return {
        date: null,
        time: null
      };
    }
  },
  watch: {},
  mounted() {
    this.objects();
  },
  methods: {
    statusAll(index) {


      const array = {
        container_damaged_or_lying: 'Контейнер поврежден или лежит.',
        container_outside_the_area: 'Контейнер за пределами территории.',
        debris_garbage_heaps: 'Кучи строительного мусора.',
        dumpster_overfilled: 'Мусорный контейнер переполнен',
        kgo_heaps: 'Кучи КГО.',
        rubbish_heaps: 'Кучи мусора.',
        street_garbage_heaps: 'Кучи мусора на улице',
        tbo_heaps: 'Кучи ТБО.',
        road_snow: 'Снег на дороге.',
        sidewalk_snow: 'Снег на тротуаре.',
        snow_pile: 'Сугроб.'
      };

      this.substat = true;

      return array[index] ? array[index] : null;
    },
    async objects() {
      this.photoError = false;
      this.statusHistoryDatetime = null;
      if (this.startDate && this.startTime) {
        this.statusHistoryDatetime = this.dateFilter(`${this.startDate} ${this.startTime}`);
      }
      this.cams = [];
      api.getLoadId({ id: this.$route.params.id, date: this.statusHistoryDatetime }).then(result => {
        this.firstTimeLogged = result?.all[0].firstTimeLogged;
        this.isLoading = false;
        if (result?.all[0]?.camera_status) {
            this.camStatus = result?.all[0]?.camera_status[0]?.status;
            this.cams.push(result?.all[0]?.camera_status[0]);
        }
        if (result?.all[0]?.collectors) {
            this.shot_dt = result?.all[0].collectors[0]?.shot_dt;
        }
        if (this.cams[0]?.photo === null) {
          this.photoError = true;
          this.$rir.notification.send({
            title: 'Ошибка! Нет изображения',
            seconds: 5,
            hiddenClose: false,
            iconOptions: {
              icon: 'warning',
              fill: 'fargo'
            }
          });
        }
      });
    },
    inputDate() {
      this.objects();
    },
    inputTime() {
      this.objects();
    },
    onViewImage(value) {
      this.$refs.modal.openModal(ModelViewImage, {
        file: `/${value}`
      });
    },
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();
      const hh = date.getHours();
      let min = date.getMinutes();
      if (min < 10) min = `0${min}`;

      return `${yy}-${mm}-${dd} ${hh}:${min}`;
    },
    dateFilterOutput(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();
      let hh = date.getHours();
      if (hh < 10) hh = `0${hh}`;
      let min = date.getMinutes();
      if (min < 10) min = `0${min}`;

      return `${dd}.${mm}.${yy} ${hh}:${min}`;
    },
    statusInfo(status) {
      if (status === 1) return { color: 'matrix--text', text: 'Нарушений нет' };
      if (status === 2) return { color: 'lebowski--text', text: 'Обнаружены недостатки' };
      if (status === 3) return { color: 'fargo--text', text: 'Выявлены нарушения' };

      return { color: 'matrix--text', text: 'Нарушений нет' };
    },
    back() {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id: this.$route?.query.id },
        query: {
          tab: this.$route?.query?.tab,
          point: this.$route?.query?.point,
          type: this.$route?.query?.type
        }
      });

      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.expand-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rir-amelie);
  z-index: 9999;
  overflow: auto;
  box-sizing: content-box;

  &__wrapper {
    height: 50vh;
    border-radius: 15px;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--rir-django);
    //&:before {
    //  top: 0;
    //  width: 100%;
    //  height: 100%;
    //  content: "";
    //  display: block;
    //  position: absolute;
    //  animation: keyframes-pulse 1.6s ease .4s infinite;
    //  background-color: var(--rir-django);
    //}
    //
    //@keyframes keyframes-pulse {
    //  0% {
    //    opacity: 1
    //  }
    //  50% {
    //    opacity: .32
    //  }
    //  to {
    //    opacity: 1
    //  }
    //}
  }

  &__main-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.margin-class {
  margin-top: -1px;
}

.width-class {
  width: 100%;
}

.search-class {
  position: absolute;
  padding-top: 14px;
  padding-left: 28px
}

.delete-sensor {
  padding-top: 4px;
  color: #E14761;
  cursor: pointer;
}

.time-z {
  width: 100%;
  z-index: 1400;
}

.margin-bottom {
  margin-bottom: 40px;
}

.h-tko {
  overflow: auto;
}

.icon-search-div {
  width: 76px;
  height: 48px;
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:hover {
    background: #04153E;
    opacity: 0.48;
    border-radius: 8px;
  }
}

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50%;

  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 1px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.align-center {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.float {

}

.time {

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.img {

  width: 16px;
  height: 16px;
  margin-bottom: 10px;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.archive {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  width: 20%;
  color: #3D75E4;
  cursor: pointer;
}

.edit {
  &__map {
    display: block;
    width: 650px;
    height: 377px;
  }
}

.search {
  color: #3D75E4;
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.text-covid {
  display: table;

  width: calc(100% - 174px)
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

::v-deep .rir-popover__content .rir-select__content {
  border-radius: 8px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.float-left {

}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.street {
}

.street:hover {
  opacity: 1;
}

.print {
  cursor: pointer;
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  margin-top: 1px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
  color: #E5E5E5;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
}

.float {

}

.header-link {
  margin-top: 21px;
  padding-bottom: 32px;
}

.time-days {
  width: calc(50% - 10px);
  z-index: 13;
}

.rir-input__after {
  max-height: 200px;
}

.custom {
  width: 100%;
}

.opacity {
  opacity: 1;
  border-radius: 8px;
}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.rir-list > .rir-list-item * {
  opacity: 1;
}

.delete-pribor {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #E14761;
  margin-left: 12px;
  margin-top: 32px;
  margin-bottom: 60px;
  cursor: pointer;
}

.tko {
  display: table;
  margin-left: 20px;
  margin-top: -3px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-conteiner {
  padding-bottom: 16px;
  width: 100%;
  display: table;
}

.title-params {
  @include reset-list;
  right: 69.8%;
  top: 10%;
  bottom: 10%;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;

  width: 151px;
  max-width: 151px;
  margin-right: 24px;
}

.lebowskis {
  color: #D06E0B;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
