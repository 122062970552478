<template>
  <div class="r-map-balloon pointer pa-4">
    <a
      :href="`/admin/appeals/${marker.id}`"
    >
        <p
          class="сaprino mb-1"
          :class="status[marker.hidden].color"
        >
          {{ status[marker.hidden].title }}
        </p>
        <p class="roquefort mb-1">
          {{ marker.description }}
        </p>
        <p class="сaprino opacity-72">
          {{ marker.address }}
        </p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BalloonAppeals',
  props: {
    marker: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      status: {
        0: {
          title: 'Назначено',
          color: 'rocky--text'
        },
        '-1': {
          title: 'Удалено',
          color: 'metropolis--text'
        },
        1: {
          title: 'Удалено',
          color: 'metropolis--text'
        },
        2: {
          title: 'Готово',
          color: 'matrix--text'
        },
        3: {
          title: 'В плане',
          color: 'lebowski--text'
        },
        4: { // //////////////
          title: 'Отложено',
          color: 'lebowski--text'
        },
        5: {
          title: 'В работе',
          color: 'gentlemen--text'
        },
        6: {
          title: 'В работе',
          color: 'gentlemen--text'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 300px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}
.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.leaflet-container a {
  color: unset !important;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content {
  width: unset !important;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}
.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup-content p {
  margin: 4px 0 !important;
}
 .сaprino {
   font-size: 10px;
   line-height: 12px;
   font-weight: 400;
 }
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.leaflet-container a {
  color: unset !important;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content {
  width: unset !important;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}
.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup-content p {
  margin: 4px 0 !important;
}
.roquefort {
  color: #0e1420;
}

.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
.ymaps-2-1-79-balloon__content {
  font: unset !important;
}
</style>
