<template>
  <div class="expand-photo__wrapper">
    <img
      :src="file"
      class="expand-photo__main-photo"
    >
  </div>
</template>

<script>
export default {
  name: 'ModalViewImage',
  props: {
    file: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.expand-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rir-amelie);
  z-index: 9999;
  overflow: auto;
  box-sizing: content-box;

  &__wrapper {
    display: flex;
    justify-content: center;
    padding: 50px;
    align-items: center;
    height: 100%;
  }

  &__main-photo {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
  }
}
</style>
