<template>
  <div>
    <div class="d-flex align-items-center mb-6">
      <r-button-action
        title="Выгрузить отчет"
        icon="file-download"
        @click="openDownloadReportModal"
        :disabled="this.reportFileUrl === null"
      />
      <r-button-action
        title="Сформировать отчет"
        class="ml-6"
        @click="getReport"
        :disabled="reportTableLoader"
      />
      <r-checkbox
        class="ml-6"
        :disabled="reportTableLoader"
        title="Обновить данные"
        v-model="noCache"
      />

      <!--<r-button-action
        title="Сформировать отчет"
        class="mb-6  ml-6"
        @click="getReport"
        :disabled="reportTableLoader"
      />-->
    </div>

    <div class="flex align-items-center mb-6">
      <r-date-picker
        :period="true"
        label="Дата"
        v-model="filterDate"
        class="flex-1 mr-6"
        :start-date="startDate"
        :end-date="endDate"
      />
      <r-select
        v-model="factExport"
        class="flex-1 mr-6"
        :items="factExportItems"
        label="Факт вывоза"
      />
      <r-input
        class="flex-1"
        label="Поиск"
        v-model.trim="search"
        after-icon="search"
      />
    </div>

    <!--
    <p class="burrata mt-10 mb-6">
      Тело отчета Адресный (за дату или период)
    </p>

    <r-table
      :headers="secondTable.headers"
      :items="secondTable.items"
      size="creusa"
    />-->
    <div class="mb-2 d-flex justify-content-end">
      <p class="parmigiano opacity-48">
        {{ !!searchText ? 'Найдено' : 'Всего' }} {{ objectsCount }}
      </p>
    </div>
    <div class="scroll_table">
      <drag-scroll>
        <r-table
          :headers="reportTable.headers"
          :items="filteredObjects"
          size="creusa"
          :is-loading="reportTableLoader"
          :is-fixed-header="true"
          color-header="trainspotting"
        >
          <template #address="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ item['address'] }}
            </p>
          </template>
          <template #delayOk="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delayOk'] === 'boolean' ? (item['delayOk'] ? '&#10761;' : '') : item['delayOk'] }}
            </p>
          </template>
          <template #delayBad="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delayBad'] === 'boolean' ? (item['delayBad'] ? '&#10761;' : '') : item['delayBad'] }}
            </p>
          </template>
          <template #delay24="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delay24'] === 'boolean' ? (item['delay24'] ? '&#10761;' : '') : item['delay24'] }}
            </p>
          </template>
          <template #delay48="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delay48'] === 'boolean' ? (item['delay48'] ? '&#10761;' : '') : item['delay48'] }}
            </p>
          </template>
          <template #delay72="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delay72'] === 'boolean' ? (item['delay72'] ? '&#10761;' : '') : item['delay72'] }}
            </p>
          </template>
          <template #delay144="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ typeof item['delay144'] === 'boolean' ? (item['delay144'] ? '&#10761;' : '') : item['delay144'] }}
            </p>
          </template>

          <template #dateLast="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ item['dateLast'] }}
            </p>
          </template>
          <template #distance="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ item['distance'] }}
            </p>
          </template>
          <template #speed="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ item['speed'] }}
            </p>
          </template>
          <template #delay="{ item }">
            <p
              :class="{'fargo--text': item.warning}"
              :title="item.warning ? 'Проверьте местоположение точки сбора ТКО на карте' : null"
            >
              {{ item['delay'] }}
            </p>
          </template>
        </r-table>
      </drag-scroll>
      <!--<scroll-bar
        :options="{ alwaysShowTracks: true }"
        ref="scrollView"
      >

      </scroll-bar>-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import DownloadReportModal from './DownloadReportModal.vue';
import { wordMatch } from '../helpers/utils';
import DragScroll from './DragScroll.vue';

export default {
  name: 'TabReporting',
  components: {
    DragScroll,
    ScrollBar
  },
  data() {
    return {
      filterDate: null,
      factExport: 0,
      factExportItems: [
        {
          title: 'Все',
          id: 0
        },
        {
          id: 'delayOk',
          title: 'Убрано'
        },
        {
          id: 'delayBad',
          title: 'Не убрано всего'
        },
        {
          id: 'delay24',
          title: 'Не убрано 1 день'
        },
        {
          id: 'delay48',
          title: 'Не убрано 2 дня'
        },
        {
          id: 'delay72',
          title: 'Не убрано 3 и более дней'
        },
        {
          id: 'delay144',
          title: 'Не убрано 6 и более дней'
        }
      ],
      searchText: null,
      timeoutId: null,
      reportTable: {
        headers: [
          {
            field: 'address',
            name: 'Точка сбора ТКО',
            slot: true,
            style: {
              width: '400px'
            }
          },
          {
            field: 'delayOk',
            name: 'Убрано с соблюдением нормативного срока в текущую дату/период',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delayBad',
            name: 'Убрано с нарушением нормативного срока в текущую дату/период',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delay24',
            name: 'Не убрано 1 день',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delay48',
            name: 'Не убрано 2 дня',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delay72',
            name: 'Не убрано 3 и более дней',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delay144',
            name: 'Не убрано 6 и более дней',
            slot: true,
            sort: false,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'dateLast',
            name: 'Дата последнего вывоза',
            slot: true,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'distance',
            name: 'Расстояние до точки, м',
            slot: true,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'speed',
            name: 'Скорость, км/ч',
            slot: true,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          },
          {
            field: 'delay',
            name: 'Макс. невывоз, ч',
            slot: true,
            alignPosition: 'center',
            style: {
              width: '200px',
              textAlign: 'center'
            }
          }
          /* {
             field: 'extraVisits',
             name: 'Повторные заезды',
             slot: true
           } */
        ],
        items: []
      },
      reportFileUrl: null,
      reportTableLoader: false,
      startDate: null,
      endDate: null,
      noCache: false
    };
  },
  computed: {
    objectsCount() {
      const count = this.filteredObjects?.length - 1;
      return count > 0 ? count : 0;
    },
    filteredObjects() {
      let list = this.$store.state.binsTruckLog;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.address || '', this.searchText) <= 0.5 || el?.total
        );
      }
      if (this.factExport !== 0) {
        list = list.filter(el => el[this.factExport] || el?.total);
      }
      const totalObject = list.find(el => el.total === true);

      if (totalObject) {
        const result = list.reduce((acc, item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (key.startsWith('delay') && value) {
              acc[key] = (acc[key] || 0) + 1;
            }
          });
          return acc;
        }, {});
        totalObject.delayOk = (result.delayOk - 1) || 0;
        totalObject.delayBad = (result.delayBad - 1) || 0;
        totalObject.delay24 = (result.delay24 - 1) || 0;
        totalObject.delay48 = (result.delay48 - 1) || 0;
        totalObject.delay72 = (result.delay72 - 1) || 0;
        totalObject.delay144 = (result.delay144 - 1) || 0;
      }

      return list;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  async created() {
    this.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    this.endDate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.filterDate = [
      moment().format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ];
   // await this.getReport();
  },
  methods: {
    async getReport() {
      this.reportTableLoader = true;
      this.reportFileUrl = null;
      this.reportTable.items = [];
      this.$rir.notification.send({
        title: 'Отчет формируется. Это может занять несколько минут',
        seconds: 5,
        hiddenClose: false
      });
      const data = {
        dateFrom: moment(this.filterDate[0]).format('DD.MM.YYYY'),
        dateTo: moment(this.filterDate[1]).format('DD.MM.YYYY')
      };
      if (this.noCache === true) {
        data.noCache = 1;
      }
      await this.$store.dispatch('getBinsTruckLog', data).then(res => {
        // this.reportTable.items.push(...res.report);
        this.reportFileUrl = res.url;
      }).finally(() => {
        this.noCache = false;
        this.reportTableLoader = false;
      });
    },
    openDownloadReportModal() {
      window.open(this.reportFileUrl, '_blank');
      //  this.$rir.modal.open(DownloadReportModal);
    }
  }
};
</script>

<style scoped lang="scss">

.scroll_table {
  position: relative;
  width: 100%;
  overflow: hidden;
  ::v-deep tbody tr td:not(:first-child) .RTable__row{
   justify-content: center;
  }
  ::v-deep thead tr th{
    position: sticky;
    top: 0px;
    background-color: var(--rir-trainspotting)
  }
  ::v-deep tbody tr td:first-child{
    position: sticky;
    left: 0;
    background-color: var(--rir-trainspotting)
  }
}
::v-deep .c-scroll-view {
  padding-top: 20px;
}
::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 0px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

::v-deep .scrollbar-track-y {
  background: #f6f9fe;
  padding: 4px 0;
  width: 20px;
  //top: 50px;
  //height: calc(100% - 50px);
  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    width: 12px;
    left: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      left: 50%;
      margin-left: -3px;
    }

    &:before {
      top: 4px;
    }

    &:after {
      bottom: 4px;
    }
  }
}

</style>
