<template>
  <section>
    <a
      @click.stop.prevent="openModalArchive"
      class="pointer flex"
    >
      <r-icon
        icon="play"
        class="mr-2"
      />
      <span class="text-rocky sulguni">Смотреть видео</span>
    </a>
  </section>
</template>

<script>
import ArchiveVideoModal from '@/views/camers/ArchiveVideoModal';

export default {
  name: 'CamVideo',
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    openModalArchive() {
      this.$rir.modal.open(ArchiveVideoModal, {
        item: this.item
      });
    }
  }
};
</script>
