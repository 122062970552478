<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
          />
        </div>
        <div
          class="parent align-center"
          v-show="SensorLogin"
        >
          <div class="container-upload">
            <h1 class="ricotta mb-2">
              Поиск ближайших датчиков
            </h1>
            <div class="mb-6 font">
              Введите логин и пароль провайдера для поиска
            </div>
            <div class="flex mt-2 mb-8 video">
              <r-input
                :value="null"
                class="video__login"
                placeholder="Логин"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                :value="null"
                class="video__pass"
                placeholder="Пароль"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <div class="flex mt-2 video">
              <r-button
                class="flex-1"
                :disabled="true"
                title="Найти"
              />
            </div>
          </div>
        </div>
        <div
          class="parent align-center"
          v-show="SensorCancel"
        >
          <div class="container-upload">
            <r-icon
              class="mr-2"
              icon="close"
              fill="fargo"
              size="56"
            />
            <h1 class="ricotta mb-2">
              Поиск ближайших датчиков
            </h1>
            <div class="mb-6 font">
              Рядом не нашлось ни одного датчика или они уже привязаны к другим точкам сбора ТКО
            </div>
            <template />
            <div class="flex mt-2 video">
              <r-button
                class="flex-1"
                :disabled="true"
                title="Завершить"
              />
            </div>
          </div>
        </div>
        <div
          class="parent align-center"
          v-show="SensorAdd"
        >
          <div class="container-upload">
            <h1 class="ricotta mb-6">
              Поиск ближайших датчиков
            </h1>
            <div class="mb-6 font">
              Выберете один из найденных датчиков
            </div>
            <template>
              <!--              <r-radio-group v-model="model" isColumn>
                <r-radio value="radio-1" label="Датчик №4321 на растоянии 78 метров"/>
                <r-radio value="radio-2" label="Датчик №346 на растоянии 25 метров"/>
                <r-radio value="radio-3" label="Датчик №7876 на растоянии 9 метров"/>
              </r-radio-group>-->
            </template>
            <div class="flex mt-8 video">
              <r-button
                class="flex-1"
                :disabled="false"
                title="Подключить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchNotViewSensor',
  data() {
    return {
      upload: false,
      SensorLogin: false,
      SensorCancel: false,
      SensorAdd: true,
      model: 'radio-1'
    };
  },
  methods: {
    showSensor() {
      this.upload = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  display: inline-block;

  &__login {
    width: calc(50% - 12px);
    float: left;
  }

  &__pass {
    width: calc(50% - 12px);
    float: right;
  }
}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

</style>
