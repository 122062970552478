<template>
  <div>
    <div
      class="r-map-balloon "
      :data-id-balloon="id"
    >
      <img
        data-action="model"
        class="r-map-balloon__img"
        v-if="photo"
        :src="photo"
        alt=""
      >
      <div

        class="pointer"
      >
        <div class="r-map-balloon__info">
          <p class="mt-2 roquefort">
            {{ type }}
          </p>
          <p class="mt-2 сaprino r-map-balloon__address">
            {{ address }}
          </p>
          <p
            v-if="status > 0"
            :class="'mt-2 mozzarella ' + statusInfo(status).color"
          >
            {{ statusInfo(status).text }}
          </p>
          <p
            class="mt-2 сaprino metropolis--text r-map-balloon__address"
            v-if="shotdt"
          >
            Время вывоза: {{ dateFilter(shotdt) }}
          </p>
          <div
            class="obj-card__counters flex obj-card__address mozzarella style-marin-house mt-2"
            v-if="loadCont(extraData, typeId) "
          >
            <div
              class=" mr-2 img"
              v-show="paper"
            >
              <img src="../../public/icon/knob.svg">
            </div>
            <div
              class=" mr-2  img"
              v-show="glass"
            >
              <img src="../../public/icon/glass.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="metal"
            >
              <img src="../../public/icon/paper.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="plastic"
            >
              <img src="../../public/icon/plastic.svg">
            </div>
            <div
              class="mr-2 img"
              v-show="other"
            >
              <img src="../../public/icon/different.svg">
            </div>
          </div>
          <!--<div
            class="obj-card__counters flex obj-card__address mozzarella style-marin-house mt-1"
            v-if="typeId === 5"
          >
            Информация о пунктах приема собрана в рамках проекта «Чистый город начинается с тебя»
          </div>-->
          <div class="mt-2">
            <div class="flex align-cenetr">
              <div class="flex mr-5">
                <r-icon
                  class="mr-2"
                  icon="clock"
                  fill="rocky"
                  size="16"
                />
                <span
                  class="briscola clock mb-2"
                  v-if="extraData !== null"
                >
                  {{ (schedule ? schedule : (extraData ? extraData.days + ' ' + extraData.clock : '')) }}
                </span>
                <span
                  class="briscola clock mb-2"
                  v-else
                >
                  -
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BalloonCard',
  props: {
    type: {
      type: String
    },
    schedule: {
      type: String
    },
    extraData: {
      type: Object
    },
    address: {
      type: String
    },
    id: {
      type: String
    },
    photo: {
      type: String
    },
    shotdt: {
      type: String
    },
    status: {
      type: String
    },
    typeId: {
      type: Number
    }
  },
  data() {
    return {
      paper: false,
      glass: false,
      metal: false,
      plastic: false,
      other: false
    };
  },
  methods: {

    statusInfo(status) {
      if (status === 1) return { color: 'matrix--text', text: 'Нарушений нет' };
      if (status === 2) return { color: 'lebowski--text', text: 'Обнаружены недостатки' };
      if (status === 3) return { color: 'fargo--text', text: 'Выявлены нарушения' };

      return { color: 'matrix--text', text: 'Нарушений нет' };
    },
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();
      let hh = date.getHours();
      if (hh < 10) hh = `0${hh}`;
      let min = date.getMinutes();
      if (min < 10) min = `0${min}`;

      return `${dd}.${mm}.${yy} ${hh}:${min}`;
    },
    loadCont(garbage, type) {
      if (!garbage?.garbageType) return true;

      if (garbage !== null && type === 5) {
        const garbageItem = garbage?.garbageType?.split(',');
        garbageItem.forEach(element => {
          if (element === 'Бумага') {
            this.paper = true;
          }
          if (element === 'Стекло') {
            this.glass = true;
          }
          if (element === 'Металл') {
            this.metal = true;
          }
          if (element === 'PET пластик') {
            this.plastic = true;
          }
          if (element === 'Другое') {
            this.other = true;
          }
        });
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    cursor: pointer;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
<style>
.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}
.leaflet-container a {
  color: unset !important;
}
.leaflet-popup-tip-container {
  display: none;
}
.leaflet-popup-content {
  width: unset !important;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 20px;
}
.leaflet-popup-content {
  margin: 0px 0px 0px 0px !important;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup-content p {
  margin: 4px 0 !important;
}
.roquefort {
  color: #0e1420;
}

.clock {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
</style>
