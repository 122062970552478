<template>
  <div>
    <loader v-if="isLoading" />

    <div
      class="objects__list mt-6"
      v-else
    >
      <router-link
        v-for="item in filteredObjects"
        :key="item.id"
        :to="{
          name: 'edit',
          params: { id: item.id },
          query: {
            tab: 'table',
            point: isUnderVision,
            type: isType
          }
        }"
      >
<!--        <div-->
<!--          class="obj-card"-->
<!--          v-if="item.type === 5"-->
<!--        >-->
<!--          <div>-->
<!--            <r-icon-->
<!--              class="mr-3"-->
<!--              icon="recycling"-->
<!--              fill="rocky"-->
<!--              size="32"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="obj-card__info flex-1">-->
<!--            <div class="obj-card__title sulguni flex roquefort">-->
<!--              {{ getType(item.type).title }}-->
<!--            </div>-->
<!--            <div-->
<!--              class="obj-card__counters__address flex mt-2 mozzarella"-->
<!--            >-->
<!--              {{ item.address }}-->
<!--            </div>-->
<!--            &lt;!&ndash;<div class="obj-card__counters flex obj-card__address mozzarella style-marin-house"-->
<!--                 v-if="loadCont(item.id, item.extraData) ">-->
<!--              <div class=" mr-3 img tooltipIcon" v-show="paper[item.id]">-->
<!--                <img src="../../public/icon/knob.svg">-->
<!--                <span class="tooltiptextIcon">Бумага</span>-->
<!--              </div>-->
<!--              <div class=" mr-3 img tooltipIcon" v-show="glass[item.id]">-->
<!--                <img src="../../public/icon/glass.svg">-->
<!--                <span class="tooltiptextIcon">Стекло</span>-->
<!--              </div>-->
<!--              <div class="mr-3 img tooltipIcon" v-show="metal[item.id]">-->
<!--                <img src="../../public/icon/paper.svg">-->
<!--                <span class="tooltiptextIcon">Металл</span>-->
<!--              </div>-->
<!--              <div class="mr-3 img tooltipIcon" v-show="plastic[item.id]">-->
<!--                <img class="other-icon" src="../../public/icon/plastic.svg">-->
<!--                <span class="tooltiptextIcon">Пластик</span>-->
<!--              </div>-->
<!--              <div class="mr-3 img tooltipIcon" v-show="other[item.id]">-->
<!--                <img src="../../public/icon/different.svg">-->
<!--                <span class="tooltiptextIcon">Другое</span>-->
<!--              </div>-->
<!--            </div>&ndash;&gt;-->
<!--            <div-->
<!--              class=" obj-card__counters align-center sulguni obj-card__title flex mozzarella style-marin-house"-->
<!--            >-->
<!--              <div-->
<!--                class="mr-1 float flex mozzarella text-c13"-->
<!--                v-if="item.extraData !== null"-->
<!--              >-->
<!--                <r-icon-->
<!--                  fill="rocky"-->
<!--                  class="mr-1 float"-->
<!--                  icon="clock"-->
<!--                  size="16"-->
<!--                />-->
<!--                {{-->
<!--                  (item.extraData.another ? item.extraData.another : item.extraData.days + ' ' + item.schedule)-->
<!--                }}-->
<!--              </div>-->
<!--              <div-->
<!--                class="mr-1 float tooltip mozzarella"-->
<!--                v-if="item.camera_guid && item.camera_guid?.length > 0 && item.camera_guid[0] !== 'test'"-->
<!--              >-->
<!--                <svg-->
<!--                  class="mr-1 ml-5 float"-->
<!--                  width="16"-->
<!--                  height="16"-->
<!--                  viewBox="0 0 16 16"-->
<!--                  fill="none"-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--                >-->
<!--                  <path-->
<!--                    fill-rule="evenodd"-->
<!--                    clip-rule="evenodd"-->
<!--                    d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V3C16 4.10457 15.1046 5 14 5H2C0.895431 5 0 4.10457 0 3V2ZM14 2H2V3H14V2Z"-->
<!--                    fill="#D06E0B"-->
<!--                  />-->
<!--                  <path-->
<!--                    d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11Z"-->
<!--                    fill="#D06E0B"-->
<!--                  />-->
<!--                  <path-->
<!--                    fill-rule="evenodd"-->
<!--                    clip-rule="evenodd"-->
<!--                    d="M4 12.4721V10C4 7.79086 5.79086 6 8 6C10.2091 6 12 7.79086 12 10V12.4721C13.2275 11.3735 14 9.77688 14 7.99988C14 7.70788 13.9792 7.42133 13.9392 7.14148C13.861 6.59476 14.2408 6.08816 14.7875 6.00996C15.3342 5.93176 15.8408 6.31157 15.919 6.85829C15.9724 7.23165 16 7.61284 16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 7.61284 0.0275596 7.23165 0.0809646 6.85829C0.159167 6.31157 0.665766 5.93176 1.21249 6.00996C1.75921 6.08816 2.13902 6.59476 2.06081 7.14148C2.02078 7.42133 2 7.70789 2 7.99988C2 9.77688 2.7725 11.3735 4 12.4721ZM6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10V13.6585C9.37444 13.8796 8.70127 13.9999 8 13.9999C7.29873 13.9999 6.62556 13.8796 6 13.6585V10Z"-->
<!--                    fill="#D06E0B"-->
<!--                  />-->
<!--                </svg>-->

<!--                Наблюдение-->
<!--                <span class="tooltiptext">Установлено наблюдение</span>-->
<!--              </div>-->
<!--              <div class=" ml-5 d-flex align-items-center">-->
<!--                <r-icon-->
<!--                  fill="rocky"-->
<!--                  class="mr-1"-->
<!--                  icon="star"-->
<!--                  size="16"-->
<!--                />-->
<!--                {{ item.rating || '-' }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex align-center">-->
<!--            <additional-menu>-->
<!--              <ul class="obj-card__menu">-->
<!--                <li-->
<!--                  class="sulguni d-flex pointer align-items-center"-->
<!--                  @click.stop.prevent="DeleteTKO(item)"-->
<!--                >-->
<!--                  <r-icon-->
<!--                    class="mr-2"-->
<!--                    icon="delete"-->
<!--                    fill="fargo"-->
<!--                  />-->
<!--                  <div>-->
<!--                    Удалить-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </additional-menu>-->
<!--          </div>-->
<!--        </div>-->
        <div
          class="obj-card"
        >
          <div>
            <r-icon
              fill="rocky"
              class="mr-4"
              :icon="getType(item.type).icon || ''"
              size="32"
            />
          </div>
          <div class="obj-card__info flex-1 roquefort">
            <div :class="'obj-card__title sulguni flex mozzarella style-marin-house'">
              {{ getType(item.type).title }}
            </div>
            <div
              :class="'obj-card__counters__address flex ' + 'obj-card__address mozzarella style-marin-house'"
            >
              {{ item.address }}
            </div>

            <div
              class=" obj-card__counters align-center sulguni obj-card__title flex mozzarella style-marin-house"
            >
              <div class="mr-1 float flex text-c13">
                <r-icon
                  fill="rocky"
                  class="mr-1 float"
                  icon="clock"
                  size="16"
                />
                {{
                  (item.schedule ? item.schedule : (item.extraData ? item.extraData.days + ' ' + item.schedule : ''))
                }}
              </div>
              <div
                class="mr-1 float tooltip mozzarella"
                v-if="(isView === 'analytics' && item.camera_guid && item.camera_guid?.length > 0 && item.camera_guid[0] !== 'test')
                || (isView === 'video' && item.camera_object.length > 0)"
              >
                <svg
                  class="mr-1 ml-5 float"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V3C16 4.10457 15.1046 5 14 5H2C0.895431 5 0 4.10457 0 3V2ZM14 2H2V3H14V2Z"
                    fill="#D06E0B"
                  />
                  <path
                    d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11Z"
                    fill="#D06E0B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 12.4721V10C4 7.79086 5.79086 6 8 6C10.2091 6 12 7.79086 12 10V12.4721C13.2275 11.3735 14 9.77688 14 7.99988C14 7.70788 13.9792 7.42133 13.9392 7.14148C13.861 6.59476 14.2408 6.08816 14.7875 6.00996C15.3342 5.93176 15.8408 6.31157 15.919 6.85829C15.9724 7.23165 16 7.61284 16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 7.61284 0.0275596 7.23165 0.0809646 6.85829C0.159167 6.31157 0.665766 5.93176 1.21249 6.00996C1.75921 6.08816 2.13902 6.59476 2.06081 7.14148C2.02078 7.42133 2 7.70789 2 7.99988C2 9.77688 2.7725 11.3735 4 12.4721ZM6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10V13.6585C9.37444 13.8796 8.70127 13.9999 8 13.9999C7.29873 13.9999 6.62556 13.8796 6 13.6585V10Z"
                    fill="#D06E0B"
                  />
                </svg>
                Наблюдение
                <span class="tooltiptext">Установлено наблюдение</span>
              </div>
              <div class="ml-5 d-flex align-items-center">
                <r-icon
                  fill="rocky"
                  class="mr-1"
                  icon="star"
                  size="16"
                />
                {{ item.rating || '-' }}
              </div>
            </div>
          </div>
          <div class="flex align-center">
            <additional-menu>
              <ul class="obj-card__menu">
                <li
                  class="sulguni flex pointer"
                  @click.stop.prevent="DeleteTKO(item)"
                >
                  <r-icon
                    class="mr-3"
                    icon="delete"
                    fill="fargo"
                  />
                  <div style="margin-top: -1px;">
                    Удалить
                  </div>
                </li>
              </ul>
            </additional-menu>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapGetters } from 'vuex';
import AdditionalMenu from './AdditionalMenu';
import DeleteModal from './DeleteModal';

export default {
  name: 'LoadCard',
  components: {
    Loader,
    AdditionalMenu,
    DeleteModal
  },
  props: {
    isView: {
      type: String
    },
    check: {
      type: Boolean,
      default: false
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    isType: {
      type: String,
      default: ''
    },
    isUnderVision: {
      type: Number,
      default: 0
    },
    sortBy: {
      type: String,
      default: 'name'
    }
  },

  emits: ['count'],
  data() {
    return {
      countOnPublic: null,
      countOnModerate: null,
      paper: [],
      glass: [],
      metal: [],
      plastic: [],
      other: [],
      showDelete: false
    };
  },
  computed: {
    ...mapGetters(['getType']),
    // Загрузка списка домов
    allObjects() {
      const { objects } = this.$store.state;
      // console.log(objects);
      return objects;
    },
    // Фильтрация списка домов
    filteredObjects() {
      let objects = [];
      if (this.allObjects !== null) {
        const objectsAll = this.$store.state.objects.all.filter(el => el.hidden !== '1');
        const objectsAllItem = objectsAll.map(el => ({
          id: String(el.id),
          address: String(el.address),
          type: parseInt(el.type),
          typeName: String(this.allObjects.garbageTypes[parseInt(el.type)]),
          FillingPercentage: (el.FillingPercentage ? el.FillingPercentage : ' - '),
          updated: (el.updated ? el.updated : ' - '),
          camera_guid: el?.camera_guid,
          extraData: {
            ...el.extraData,
            clock: el.extraData?.clock ? el.extraData?.clock : '00:00 - 00:00',
            days: el.extraData?.days ? el.extraData?.days : ''
          },
          schedule: el.schedule ? el.schedule : '00:00 - 00:00',
          garbageType: (el.extraData ? el.extraData.garbageType : ''),
          camera_object: el.camera_object ? el.camera_object : [],
          rating: el.rating
        }));
        objects.push(...objectsAllItem);
        // console.log('jgf jgf', this.isType);

        const regexp = /[,\s]+|[.\s]+/g;
        const searchItem = this.isSearch.toLowerCase().replace(regexp, '');

        objects = objects.filter(el => { // @TODO refactore
          const correctAddress = el.address.toLowerCase().replace(regexp, '').includes(searchItem);
          const correctTypeName = el.typeName.toLowerCase().replace(regexp, '').includes(searchItem);

          return correctAddress || correctTypeName;
        });

        objects = objects.filter(el => { // @TODO refactore
          if (this.isType === '' || this.isType === '0') return true;

          return el.type.toString() === this.isType;
        });
        if (this.isUnderVision === 1) {
          objects = objects.filter(object => this.isView === 'video' ?
            (!object?.camera_object || object?.camera_object.length === 0 || object?.camera_object[0] === 'test')
            : (object?.camera_guid && object?.camera_guid?.length === 0 && object?.camera_guid[0] !== 'test'));
        }
        if (this.isUnderVision === 2) {
          objects = objects.filter(object => this.isView === 'video' ?
            (object?.camera_object && object?.camera_object?.length > 0 && object?.camera_object[0] !== 'test')
            : (object?.camera_guid && object?.camera_guid?.length > 0 && object?.camera_guid[0] !== 'test')
          );
        }
        this.$emit('count', objects.length);
        // console.log('his.$emit(',objects.length)
        objects = objects.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
        //  if(this.isUnderVision) objects = objects.filter(object => !!object['camera_object'].length > 0);
        this.$store.commit('countObjects', objects.length);
        return objects;
      }
    },
    filteredContainer() {
      const objects = [
        'Все',
        'Контейнер',
        'Площадка ТКО',
        'Бункер-накопитель',
        'Мусорокамера',
        'Раздельный сбор'
      ];

      // console.log(objects);
      return objects;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  methods: {
    async DeleteTKO(item) {
      await this.$rir.modal.open(DeleteModal, {
        item
      });
    },
    prettyDate2(time) {
      const dates = new Date(time);
      return `в ${dates.getHours()}:${dates.getMinutes()}`;
    },
    loadCont(id, garbage) {
      if (garbage !== null) {
        this.paper[id] = false;
        this.glass[id] = false;
        this.metal[id] = false;
        this.plastic[id] = false;
        this.other[id] = false;
        const garbageItem = garbage.garbageType.split(',');
        garbageItem.forEach(element => {
          if (element === 'Бумага') {
            this.paper[id] = true;
          }
          if (element === 'Стекло') {
            this.glass[id] = true;
          }
          if (element === 'Металл') {
            this.metal[id] = true;
          }
          if (element === 'PET пластик') {
            this.plastic[id] = true;
          }
          if (element === 'Другое') {
            this.other[id] = true;
          }
        });
        return true;
      }
    },
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id }
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip .tooltiptext {
  margin-top: 30px;
  margin-left: -90px;
  visibility: hidden;
  width: 180px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipIcon .tooltiptextIcon {
  margin-top: 30px;
  margin-left: -30px;
  visibility: hidden;
  width: 70px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 1;
  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltipIcon:hover .tooltiptextIcon {
  visibility: visible;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}

.float-right {
  margin: 0 0 0 auto;
}

.color-card {
  color: #0e1420;
}

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;
  height: 100%;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }

  &__counters {
    margin-top: 16px;
    font-size: 13px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
}
.obj-card__counters__address {
  margin-top: 8px;
  font-size: 13px;
}
.pointer {
  cursor: pointer;
}

.align-center {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.float {

}

.time {

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.img {

  width: 16px;
  height: 16px;
  margin-bottom: 10px;
}
</style>
