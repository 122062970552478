<template>
  <div
    class="drag_scroll"
    ref="slider"
    @mousedown="dragMousedown"
    @mouseleave="dragMouseleave"
    @mouseup="dragMouseup"
    @mousemove="dragMousemove"
    @wheel="cancelMomentumTracking"
  >
    <slot />
  </div>
</template>

<script>


export default {
  name: 'DragScroll',
  data() {
    return {
      isDown: false,
      startX: null,
      scrollLeft: null,
      slider: null,
      momentumID: null,
      velX: 0
    };
  },
  created() {

  },
  methods: {
    dragMousedown(e) {
      this.isDown = true;
      this.startX = e.pageX - this.$refs.slider.offsetLeft;
      this.scrollLeft = this.$refs.slider.scrollLeft;
      this.cancelMomentumTracking();
    },
    dragMouseleave() {
      this.isDown = false;
    },
    dragMouseup() {
      this.isDown = false;
      this.beginMomentumTracking();
    },
    dragMousemove(e) {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.slider.offsetLeft;
      const walk = (x - this.startX); // scroll-fast
      const prevScrollLeft = this.$refs.slider.scrollLeft;
      this.$refs.slider.scrollLeft = this.scrollLeft - walk;
      this.velX = this.$refs.slider.scrollLeft - prevScrollLeft;
    },
    beginMomentumTracking() {
      this.cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.momentumLoop);
    },
    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
    },
    momentumLoop() {
      this.$refs.slider.scrollLeft += this.velX;
      this.velX *= 0.95;
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop);
      }
    }
  }
};
</script>

<style scoped lang="scss">

  .drag_scroll {
    //-ms-overflow-style: none; /* for Internet Explorer, Edge */
    //scrollbar-width: none;

    position: relative;
    width: 100%;
    overflow-x: scroll;
    height: 70vh;
    overflow-y: auto;
    user-select: none;
    cursor: grab;
    &:active{
      cursor: grabbing;
    }
  }
.drag_scroll::-webkit-scrollbar {
 // display: none; /* for Chrome, Safari, and Opera */
}

  .drag_scroll::-webkit-scrollbar {
    width: 22px;
  }

  .drag_scroll::-webkit-scrollbar-track {
    width: 20px;
  }

  .drag_scroll::-webkit-scrollbar-thumb {
    height: 73px;
    width: 24px;
    background-clip: padding-box;
    background-color: #e4edfb;
    border-radius: 15px;
    border: 8px solid rgba(0, 0, 0, 0);
  }

  .drag_scroll::-webkit-scrollbar-corner {
    background-color: transparent;
  }


  .drag_scroll::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  //.drag_scroll{
  //  overscroll-behavior: contain;
  //  scroll-snap-type: x mandatory;
  //  & > * {
  //    scroll-snap-align: start;
  //  }
  //
  //
  //}
</style>
