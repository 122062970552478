var render = function render(){var _vm=this,_c=_vm._self._c;return _c('r-popover',{attrs:{"disabled":_vm.readonly,"content-class":"rir-select__content rir-popover__radius"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('r-input',{staticClass:"rir-select",class:{
        disabled: _vm.readonly
      },attrs:{"readonly":"","max-width":_vm.maxWidth,"placeholder":_vm.placeholder,"label":_vm.label,"value":_vm.model},nativeOn:{"click":function($event){return on.click.apply(null, arguments)}}},[_c('a',{ref:"icon",staticClass:"rir-select__icon",attrs:{"slot":"after","tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.changeViewList.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.changeViewList.apply(null, arguments)}},slot:"after"},[_c('r-icon',{class:{
            active: _vm.showSelect
          },attrs:{"size":20,"icon":"arrow-down"}})],1)])]}}]),model:{value:(_vm.showSelect),callback:function ($$v) {_vm.showSelect=$$v},expression:"showSelect"}},[(_vm.itemsList.length)?_c('r-list',{attrs:{"overflow":""}},_vm._l((_vm.itemsList),function(item){return _c('r-list-item',{key:item.forUuId,class:{
        active: item[_vm.idValue] === _vm.modelId,
        disabled: item.disabled
      },nativeOn:{"click":function($event){!item.disabled && _vm.selectItem(item)}}},[(!_vm.isSlotItem)?[(item['icon'] === 1)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"tko","size":"16"}}):_vm._e(),(item['icon'] === 2)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"tko-place","size":"16"}}):_vm._e(),(item['icon'] === 3)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"waste-bin","size":"16"}}):_vm._e(),(item['icon'] === 4)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"waste-camera","size":"16"}}):_vm._e(),(item['icon'] === 5)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"separate","size":"16"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(item[_vm.textValue])+" ")])]:_vm._e(),_vm._t("item",null,{"item":item})],2)}),1):_c('div',{staticClass:"rir-select__no-date"},[_vm._v(" "+_vm._s(_vm.noDataItems)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }