import { render, staticRenderFns } from "./BalloonAppeals.vue?vue&type=template&id=5b3b4c0c&scoped=true"
import script from "./BalloonAppeals.vue?vue&type=script&lang=js"
export * from "./BalloonAppeals.vue?vue&type=script&lang=js"
import style0 from "./BalloonAppeals.vue?vue&type=style&index=0&id=5b3b4c0c&prod&lang=scss&scoped=true"
import style1 from "./BalloonAppeals.vue?vue&type=style&index=1&id=5b3b4c0c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3b4c0c",
  null
  
)

export default component.exports