import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import EditObject from '../views/EditObject.vue';
import NewObject from '../views/NewObject';
import CamNotViewObject from '@/views/CamNotViewObject.vue';

Vue.use(VueRouter);

const routes = [
  // Админка
  {
    path: '/',
    name: 'admin',
    component: Admin,
    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList,
        props: true
      },
      {
        name: 'edit',
        path: 'edit/:id',
        component: EditObject,
        props: true,
        meta: {
          reload: true
        }
      },
      {
        name: 'camera',
        path: 'camera/:id',
        component: CamNotViewObject,
        props: true,
        meta: {
          reload: true
        }
      },
      {
        name: 'new',
        path: 'new',
        component: NewObject,
        props: true,
        meta: {
          reload: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
