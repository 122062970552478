import Vue from 'vue';
import Rir from '@rir/vue-library';

if (process.env.NODE_ENV === 'development') {
  //import('@rir/vue-library/dist/RirLib.css');
}

Vue.use(Rir);

const options = {};
export default new Rir(options);
