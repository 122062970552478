<template>
  <div class="objects">
    <admin-header
      :title="moduleTitle"
      :back="false"
    />
    <r-tabs
      class="mt-7 mb-6"
      :items="tabs"
      v-model="activeTabId"
    />
    <tab-general
      v-if="activeTabId.id === 'general'"
      :is-view="isView"
    />
    <tab-reporting v-if="activeTabId.id === 'reporting'" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabGeneral from '../components/TabGeneral.vue';
import TabReporting from '../components/TabReporting.vue';
import PlaceApi from '@/api/PlaceApi';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    TabGeneral,
    TabReporting
  },
  data() {
    return {
      isReports: false,
      isView: false,
      activeTabId: {
        id: 'general',
        title: 'Общие сведения'
      },
      tabs: [
        {
          id: 'general',
          title: 'Общие сведения'
        }
      ]
    };
  },
  computed: {
    moduleTitle() {
      return 'Контроль вывоза ТКО';
    }
  },
  async created() {
    const response = await new PlaceApi().systemOptions();

    this.isReports = response?.ntechlabConfig?.reports === true ? true : false;
    if (this.isReports === true) {
      this.tabs.push({
        id: 'reporting',
        title: 'Отчетность'
      });
    }
    let isView = response?.ntechlabConfig?.view;
    this.isView = isView == 'analytics' || isView == 'video' ? isView : false;
  },
  async activated() {
    this.activeTabId = this.tabs.find(item => item.id === this.$route.query.tab) || {
      id: 'general',
      title: 'Общие сведения'
    };
  }
};
</script>

<style lang="scss" scoped>

</style>
