var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"map",staticClass:"objects__map mt-6"},[_c('rir-map',{ref:"myChild",attrs:{"array-for-heat-map":_vm.heatmapData,"heatmap":_vm.heatmap,"isView":_vm.isView,"legend":true},on:{"onInit":_vm.mapInit}},[_vm._l((_vm.filteredObjects),function(marker){return _c('ymap-marker',{attrs:{"coords":[`${marker.lat}`, `${marker.lng}`],"marker-id":marker.id,"options":{
        hideIconOnBalloonOpen: false
      },"icon":(_vm.isView == 'analytics'
        ?
        (marker.status ?
        _vm.getMapIcons(marker.type, marker.status).markerIcon :
        _vm.getMapIcons(marker.type, 'stat').markerIcon)
        :
        (marker.status ?
        _vm.getMapIcons(marker.type,_vm.isView === 'video' ? 2 : 'stat').markerIconCam :
        _vm.getMapIcons(marker.type).markerIcon)),"balloon":{header: {id: marker.id, name: marker.address}},"cluster-name":(_vm.isView == 'analytics'
        ?
        marker.status == 0 ? 'cluster' : _vm.statusClaster(marker.status)
        :
        (_vm.isView === 'video' ?
        (marker.camera_object.length > 0 ? 'cluster_orange' : 'cluster') : 'cluster'))},on:{"click":function($event){return _vm.onMarkerClick(marker.id)},"balloonopen":function($event){return _vm.bindListener(marker.id)}}},[_c('div',{attrs:{"slot":"balloon"},slot:"balloon"},[_c('div',{staticClass:"r-map-balloon",attrs:{"data-id-balloon":marker.id}})])])}),_vm._l((_vm.appealsGarbageData),function(marker){return (_vm.appealsGarbage)?_c('ymap-marker',{key:marker.id,attrs:{"coords":[`${marker.lat}`, `${marker.lng}`],"marker-id":marker.id,"options":{ hideIconOnBalloonOpen: false },"icon":_vm.getAppealsMapIcon(marker.hidden),"balloon":{header: {id: marker.id, name: marker.address}}}},[_c('balloon-appeals',{attrs:{"slot":"balloon","marker":marker,"data-id":marker.id},slot:"balloon"})],1):_vm._e()})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }